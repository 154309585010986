<template>
  <asom-card>
    <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
      <asom-alert class="col-span-2" v-if="error" variant="error" :error-message="error" />
      <asom-form-field
        label="User name"
        required
        :state="inputStates('username')"
      >
        <asom-input-text
          v-model="username"
          :state="inputStates('username')"
          placeholder="Enter user name"
        />
      </asom-form-field>
      <asom-form-field
        label="Person No"
        required
        :state="inputStates('personNo')"
      >
        <asom-input-text
          v-model="personNo"
          type="number"
          min="0"
          :state="inputStates('personNo')"
          placeholder="Enter person no"
        />
      </asom-form-field>
      <asom-form-field
        label="Email"
        required
        :state="inputStates('email')"
      >
        <asom-input-text
          v-model="email"
          :state="inputStates('email')"
          placeholder="Enter valid email"
        />
      </asom-form-field>
      <asom-form-field
        label="Display name"
        required
        :state="inputStates('name')"
      >
        <asom-input-text
          v-model="name"
          :state="inputStates('name')"
          placeholder="Enter display name"
        />
      </asom-form-field>
      <asom-form-field
        label="Role"
        required
        :state="inputStates('roleId')"
        error="Role is required"
      >
        <asom-input-select 
          :objectModelValue="false"
          v-model="roleId"
          :options="roleOptions"
          placeholder="Select a role"
          :state="inputStates('roleId')"
        />
      </asom-form-field>
      <asom-form-field
        label=" "
        description="Check if this is a senior officer"
        v-if="hasSeniorRole"
      >
        <asom-input-checkbox
          class="pt-2"
          v-model="isSenior"
          label="Is Senior Officer?"
        >
        </asom-input-checkbox>
      </asom-form-field>
      <div v-else></div>
      <asom-form-field
        label="MRT Line"
        required
        :state="inputStates('lineId')"
        error="MRTLine is required"
      >
        <asom-input-select
          :objectModelValue="false"
          v-model="lineId"
          :options="lineOptions"
          placeholder="Select MRT line"
          :state="inputStates('lineId')"
        />
      </asom-form-field>
      <asom-form-field label="MRT Station"
        required
        :state="inputStates('stationId')"
        error="MRT Station is required"
      >
        <asom-input-select
          :objectModelValue="false"
          v-model="stationId"
          :options="stationOptions"
          placeholder="Select MRT station"
          :state="inputStates('stationId')"
        />
      </asom-form-field>
      <div class="col-span-2 flex justify-end space-x-4">
        <asom-button text="Create" icon='user-add' @click="onSubmit" :loading="isSubmitting" />
        <asom-button text="Cancel" variant="secondary" @click="$router.push('/users')" />
      </div>
    </form>
  </asom-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, email, numeric } from '@vuelidate/validators'

import { addUser } from '../../services/user.service'
import inputStates from '@/mixins/inputStates'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'CreateUser',
  mixins: [ inputStates ],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      name: '',
      username: '',
      personNo: null,
      email: '',
      stationId: '',
      lineId: '',
      roleId: '',
      isSenior: false,
      userList: [],
      isSubmitting: false,
      error: '',
    }
  },
  validations() {
    return {
      username: { required },
      personNo: { required, numeric },
      email: { required, email },
      stationId: { required },
      lineId: { required },
      roleId: { required },
      computedRoleId: { required },
    }
  },
  mounted() {
    if (this.v$) {
      this.v$.$reset()
    }
  },
  watch: {
    roleId() {
      this.isSenior = false;
    }
  },
  computed: {
    ...mapGetters({
      roleOptions: 'adminResource/nonSeniorRoleOptions',
      lineOptions: 'smrtResource/lineOptions',
    }),
    stationOptions() {
      if (!this.lineId)
        return this.$store.getters['smrtResource/stationOptions']
      else
        return this.$store.getters['smrtResource/stationOptionsByLineId'](this.lineId)
    },
    hasSeniorRole() {
      return this.$store.getters['adminResource/hasSeniorRole'](this.roleId);
    },
    computedRoleId() {
      return this.$store.getters['adminResource/seniorRoleId'](this.roleId, this.isSenior);
    }
  },
  methods: {
    async onSubmit() {
      if (this.isSubmitting)
        return;
      this.error = ''
      this.v$.$reset()
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        const result = await addUser({
          name: this.name,
          userName: this.username,
          personNo: this.personNo,
          email: this.email,
          roleIds: [ this.computedRoleId ],
          isSenior: this.isSenior,
          lineId: this.lineId,
          stationId: this.stationId,
        })
        this.isSubmitting = false
        if (result.success)
          this.$router.push('/users')
        else
          this.error = result.payload
      } else {
        this.error = 'Please complete all required fields'
      }
    }
  }
}
</script>
